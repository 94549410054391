@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.input-section {
    position: relative;
}

.input-label {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: -0.02em;
    color: var(--general-body-text);
    margin-bottom: 0.4rem;

    @include device.mobile {
        margin-bottom: 0.9rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}

.input-container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--background-main);
    border-radius: layout.$radius-10;
    position: relative;
}

.input {
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 1.6rem;
    width: 100%;
    height: 4.8rem;
    font-size: 1.2rem;
    color: var(--period-select-text);
    outline: none;
    overflow: hidden;
    border-radius: layout.$radius-10;
    cursor: pointer;

    background-color: transparent;

    &::placeholder {
        color: var(--period-select-text);
        max-width: 75%;
        overflow-x: hidden;
    }

    &--error {
        border: none;
        border: 1px solid #ff3b3b;
    }

    &--has_icon {
        padding-left: 4rem;
    }
    @include device.mobile {
        font-size: 1rem;
        height: 3.2rem;
        padding-left: 0.9rem;
        &--has_icon {
            padding-left: 4rem;
        }
    }
}

.arrow_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    outline: none;

    &-open {
        transform: rotate(180deg);
        transition: all 0.3s;
    }

    &-close {
        transform: rotate(0);
        transition: all 0.3s;
    }

    @include device.mobile {
        width: 1rem;
        height: 1rem;
    }
}

.items-container {
    background-color: var(--background-main);
    border-radius: layout.$radius-15;
    position: absolute;
    top: 4.8rem;
    width: 100%;
    // padding: 2.4rem 0;
    font-size: 1.4rem;
    box-shadow: 0px 22.7778px 22.7778px rgba(0, 0, 0, 0.04), 0px 45.5556px 56.9444px -11.3889px rgba(0, 0, 0, 0.1);

    &-open {
        max-height: 20rem;
        overflow: auto;
        z-index: 3;
    }

    &-close {
        max-height: 0;
        overflow: hidden;
        z-index: -1;
    }

    &--light {
        background-color: color.$white;
    }

    &--with-label {
        top: 7rem;
    }
    @include device.mobile {
        top: 5.6rem;
    }
}

.item {
    padding: 0.8rem 2.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    cursor: pointer;
    color: var(--general-body-text);
    &:hover {
        font-weight: 500;
        background-color: color.$gray-2;
    }

    &--light {
        &:hover {
            font-weight: 500;
            background-color: color.$gray-5;
        }
    }
}

.icon {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 1.2rem;
    color: var(--text-general);
}
