$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$mobile-width + 1px}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin mobile-landscape {
    @media only screen and (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1}) and (orientation: landscape) {
        @content;
    }
}

@mixin touch-device {
    @media (pointer: coarse) {
        @content;
    }
}
