@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    max-width: 80rem;
    height: 61rem;
    margin: 10rem auto;
    background: var(--background-box);
    border-radius: layout.$radius-15;
    padding: 1rem 5rem;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    position: relative;
    text-align: center;
    @include device.mobile {
        background: none;
        box-shadow: none;
        border-radius: 0;
        max-width: unset;
        margin: 4rem auto;
        padding: 0;
    }
}

.loading-img {
    width: 70rem;
    @include device.mobile {
        width: 90%;
    }
}

.loading {
    font-size: 1.4rem;
    line-height: 1.2rem;
    display: inline-block;
    margin-bottom: 3rem;
}
