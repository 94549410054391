@use 'styles/colors.scss' as color;
@use 'styles/devices.scss' as device;

.form {
    @include device.mobile {
        margin-top: 4rem;
    }
}

.form-description {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--general-body-text);
    margin-bottom: 3rem;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 5rem;
}

.row {
    @include device.desktop {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1.4rem;
    }
}

.field {
    margin-bottom: 1.6rem;
    width: 100%;
}

.privacy {
    color: var(--period-select-text);
}

.input-label {
    margin-bottom: 5px;
    line-height: 17.45px;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--general-body-text);
}

.company input {
    color: var(--period-select-text);
    padding-left: 1.6rem;
    height: 4.8rem;
    // border: none;

    &::placeholder {
        color: var(--period-select-text);
    }

    @include device.mobile {
        font-size: 1rem;
        height: 3.2rem;
        padding-left: 0.9rem;
    }
}

.have_investment_experience {
    margin-top: 2.4rem;
}

.checkbox-title {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    color: var(--general-body-text);
    margin-bottom: 0.4rem;
}

.checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    margin-top: 1rem;
}

.checkbox-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    & > img {
        cursor: pointer;
    }
}

.form-error-message {
    display: block;
    text-align: start;
    margin-left: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: color.$gray-600; // needs design
    margin-top: 1rem;
}

.server-error {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    color: color.$state-error; // needs design
    margin-bottom: 2rem;
}

.submit {
    width: 100%;
}

button[disabled],
.disabled {
    cursor: not-allowed;
}

.btn-content {
    display: inline-flex;
    column-gap: 2rem;
    align-items: center;
}
