@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;

.tiny-custom-popover {
    background-color: var(--background-main);
    color: var(--general-body-text);
    padding: 1rem;
    border-radius: layout.$radius-7;
    max-width: 311px;
    animation: popover 0.4s;
    z-index: 1;
    box-shadow: 0px 22.7778px 22.7778px rgba(0, 0, 0, 0.04), 0px 45.5556px 56.9444px -11.3889px rgba(0, 0, 0, 0.1);
}

@keyframes popover {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}
