@use 'styles/devices.scss' as device;
@use 'styles/layout.scss' as layout;

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background-color: var(--background-main);
    color: var(--text-general);
    @include device.mobile {
        background-color: var(--background-box);
    }
}

.main-container {
    position: relative;
    width: 100%;

    @include device.mobile {
        width: 100vw;
        border: none;
        padding: 3rem;
    }
}
