@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg);
    z-index: 6;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.modal-main {
    position: fixed;
    color: var(--general-body-text);
    background: var(--background-box);
    width: 396px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: layout.$radius-15;
    padding: 31px 36px;
    z-index: 7;
    @include device.mobile {
        width: 95%;
    }
}

.title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 4.8rem;
}

.tab {
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 300;
    margin-top: 27px;
    &-title {
        cursor: pointer;
    }

    & .active-tab {
        font-weight: 600;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: -11px;
            left: 50%;
            transform: translateX(-50%);
            width: 60px;
            height: 3px;
            background-color: var(--setting-active-tab);
            border-radius: layout.$radius-10;
        }
    }
    @include device.mobile {
        font-size: 1.2rem;
    }
}

.email,
.password {
    font-size: 1.4rem;
    margin: 2rem;
    padding: 1rem;
}

.h3 {
    font-weight: bold;
    margin-bottom: 1rem;
}
.info-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4rem;
    padding: 1rem 0;
}

.separator {
    border: 2px solid var(--sidebar-divider);
    width: 80%;
    margin: 0 auto;
}

.close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
}

.email-flex,
.password-flex {
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding: 1rem 2rem;
}

.server-error {
    // needs design
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: color.$red;
}

.server-success {
    // needs design
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: color.$green;
}

.email-container,
.old-password-container {
    margin: 33px auto 15.4px;
}
.email-password-container,
.new-password-container {
    margin: 0 auto 43px;
}

.submit {
    width: 100%;
}
.input-label {
    margin-bottom: 5px;
    line-height: 17.45px;
    font-size: 10.26px;
    font-weight: 400;
}
