@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;
@use 'styles/colors.scss' as color;

.header {
    background: var(--background-box);
    color: var(--text-general);
    position: fixed;
    top: 21px;
    height: 87px;
    padding: 3rem 5rem;
    margin: auto;
    border-radius: layout.$radius-10;
    width: layout.$min-width;
    z-index: 8;
    @media (max-width: layout.$min-width) {
        left: 20px;
        right: 20px;
        width: unset;
    }
    @include device.mobile {
        background: var(--background-main);
        width: 100%;
        height: 55px;
        top: unset;
        left: 0;
        right: 0;
        border-radius: unset;
        padding: 1rem;
    }
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @include device.mobile {
        padding-right: unset;
    }
}

.brand-title {
    color: var(--text-general);
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
}

.account-section {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 3rem;
    & > button {
        width: 15rem;
        color: color.$white;
        border-radius: layout.$radius-7;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        cursor: pointer;
        border: none;
        background-color: var(--button-primary);
        box-shadow: 0px 4.23529px 14.1176px rgba(86, 46, 255, 0.25);
        &:hover {
            background-color: var(--button-primary-hover);
        }
    }
}

.header-logo {
    width: 101px;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    & > div:last-child {
        width: 170px;
    }
    @include device.mobile {
        gap: 1rem;
        & > div:last-child {
            width: 135px;
        }
    }
}

.modal {
    max-width: 50rem;
}
.modal-description {
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 400;
    color: var(--text-general);
    & > div {
        & > div {
            margin: 2rem 0;
        }
    }
    & > a {
        width: 100%;
        & > button {
            width: 100%;
        }
    }
}

.title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
