@use './colors.scss' as color;

:host,
:root {
    .theme--light {
        --text-general: #{color.$primary-black};

        // design theme
        --background-main: #{color.$bg-light};
        --background-box: #{color.$white};
        --button-primary: #{color.$primary-500};
        --button-outline: #{color.$primary-500};
        --button-primary-hover: #{color.$primary-800};
        --button-outline-hover: #{color.$primary-800};
        --theme-text: #{color.$gray-500};
        --sidebar-divider: #{color.$gray-200};
        --active-bg-gradient: linear-gradient(90deg, rgba(86, 46, 255, 0.07) 5%, rgba(86, 46, 255, 0) 91.87%);
        --period-select-bg-gradient: inear-gradient(90deg, #e9ecef 5%, rgba(233, 236, 239, 0) 91.87%);
        --sidebar-active: #{color.$primary-500};
        --box-header: #{color.$gray-900};
        --box-header-divider: #{color.$gray-500};
        --table-head-text: #{color.$gray-800};
        --table-row-divider: #{color.$gray-200};
        --table-row-hover: #{color.$primary-50};
        --general-body-text: #{color.$gray-700};
        --capsule-value: #{color.$gray-800};
        --invest-divest-header: #{color.$gray-700};
        --invest-divest-value: #{color.$gray-600};
        --period-select-text: #{color.$gray-600};
        --modal-bg: rgba(0, 0, 0, 0.65);
        --item-text: #{color.$gray-700};
        --item-text-mobile: #{color.$gray-700};
        --item-hover-bg: #{color.$primary-50};
        --dropdown-bg: #{color.$white};
        --pl-header: #{color.$gray-800};
        --profit-value: #{color.$green-500};
        --loss-value: #{color.$red-500};
        --info-box-value: #{color.$gray-700};
        --log-out: #{color.$red-600};
        --unchecked-radio-button: #{color.$gray-400};
        --hover-radio-button: #{color.$primary-500};
        --dropdown-shadow: 0px 10px 10px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
        --setting-active-tab: #{color.$primary-500};
        --terms-conditions: #{color.$green-600};
        --back-button: #{color.$gray-600};
        --bottom-sidebar-seperator: #{color.$gray-400};
        --metric-name: #{color.$gray-700};
        --metric-value: #{color.$gray-500};
        --info-box-title: #{color.$gray-600};
        --input-border: 1px solid #{color.$gray-200};
    }
    .theme--dark {
        --text-general: #{color.$primary-white};

        // design theme
        --background-main: #{color.$bg-2-dark};
        --background-box: #{color.$bg-dark};
        --button-primary: #{color.$primary-500};
        --button-outline: #{color.$gray-500};
        --button-primary-hover: #{color.$primary-600};
        --button-outline-hover: #{color.$gray-400};
        --theme-text: #{color.$gray-400};
        --sidebar-divider: #{color.$gray-700};
        --active-bg-gradient: linear-gradient(90deg, rgba(255, 255, 255, 0.25) 5%, rgba(255, 255, 255, 0) 91.87%);
        --period-select-bg-gradient: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.25) 5%,
            rgba(255, 255, 255, 0) 91.87%
        );
        --sidebar-active: #{color.$white};
        --box-header: #{color.$gray-200};
        --box-header-divider: #{color.$gray-600};
        --table-head-text: #{color.$gray-300};
        --table-row-divider: #{color.$gray-700};
        --table-row-hover: #{color.$bg-2-dark};
        --general-body-text: #{color.$gray-200};
        --capsule-value: #{color.$white};
        --invest-divest-header: #{color.$gray-300};
        --invest-divest-value: #{color.$gray-400};
        --period-select-text: #{color.$gray-500};
        --modal-bg: rgba(0, 0, 0, 0.9);
        --item-text: #{color.$white};
        --item-text-mobile: #{color.$gray-600};
        --item-hover-bg: rgba(255, 255, 255, 0.2);
        --dropdown-bg: #{color.$bg-2-dark};
        --pl-header: #{color.$gray-400};
        --profit-value: #{color.$green-700};
        --loss-value: #{color.$red-800};
        --info-box-value: #{color.$gray-50};
        --log-out: #{color.$red-300};
        --unchecked-radio-button: #{color.$gray-600};
        --hover-radio-button: #{color.$primary-400};
        --dropdown-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
        --setting-active-tab: #{color.$primary-300};
        --terms-conditions: #{color.$green-500};
        --back-button: #{color.$gray-300};
        --bottom-sidebar-seperator: #{color.$bg-2-dark};
        --metric-name: #{color.$gray-500};
        --metric-value: #{color.$gray-100};
        --info-box-title: #{color.$gray-100};
        --input-border: none;
    }
}
