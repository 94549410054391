.container {
    position: fixed;
    height: 100%;
    width: 0;
    z-index: 1;
    bottom: 0;
    right: 0;
    background-color: var(--background-box);
    overflow: hidden;
    box-shadow: 0px -100px 80px rgba(0, 0, 0, 0.03), 0px -64.81px 46.8519px rgba(0, 0, 0, 0.0227778),
        0px -38.52px 25.4815px rgba(0, 0, 0, 0.0182222), 0px -20px 13px rgba(0, 0, 0, 0.015),
        0px -8.15px 6.51852px rgba(0, 0, 0, 0.0117778), 0px -1.85px 3.14815px rgba(0, 0, 0, 0.00722222);
    transition: 0.5s;
    & > div {
        opacity: 0;
        transition: all 0.4s ease-out;
    }
    &-open {
        width: 100%;
        & > div {
            opacity: 1;
        }
    }
}

.close-menu {
    float: right;
    width: 2rem;
    margin-top: 23px;
    margin-right: 21px;
}
