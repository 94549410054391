@use 'styles/colors' as color;
@use 'styles/devices' as device;
@use 'styles/layout.scss' as layout;

.container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--background-box);
    border-radius: layout.$radius-7;
}

.icon {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 1rem;
    color: var(--text-general);
    &--small {
        left: unset;
        right: 0.2rem;
        top: 0.65rem;
    }
}

.input {
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 4rem;
    border: var(--input-border);
    width: 100%;
    height: 4rem;
    font-size: 12px;
    color: var(--period-select-text);
    outline: none;
    overflow: hidden;
    border-radius: layout.$radius-7;
    background-color: var(--background-main);
    &--small {
        padding-left: 1rem;
        height: 3rem;
    }
    &::placeholder {
        color: var(--period-select-text);
    }

    &:-webkit-autofill {
        background-color: var(--background-main) !important;
    }

    &-error {
        border: 1px solid color.$red-500;
    }
}

.password-meter {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    border-bottom: 2px solid color.$red-800;
    transition: width, border-color 1s, 1s;

    &-0 {
        border-color: color.$red-800;
        border-bottom-left-radius: layout.$radius-7;
    }

    &-1 {
        border-color: orange;
    }

    &-2 {
        border-color: color.$yellow-800;
    }

    &-3,
    &-4 {
        border-color: color.$green-800;
        border-bottom-right-radius: layout.$radius-7;
    }
}

.password-strength {
    position: absolute;
    font-size: 1.2rem;
    font-weight: bold;
    top: 5rem;
    left: 4rem;

    &-0 {
        color: color.$red-800;
    }

    &-1 {
        color: orange;
    }

    &-2 {
        color: color.$yellow-800;
    }

    &-3,
    &-4 {
        color: color.$green-800;
    }
}

.btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    width: 20px;
    height: 20px;
    outline: none;
}
