@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.layout-container {
    background-color: var(--background-main);
    position: relative;
    width: layout.$min-width;
    margin: 0 auto;

    @media (max-width: layout.$min-width) {
        height: calc(100vh - 5px); // 100vh - 5px(scrollbar width)
        overflow: auto;
        width: 100%;
    }
    @include device.mobile {
        height: 100vh;
        overflow: auto;
    }
}
