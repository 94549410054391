@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    gap: 1.6rem;
    width: 100%;
    @include device.mobile {
        flex-direction: column;
        gap: 5rem;
        margin-top: 2rem;
    }
}
.column {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 50%;
    @include device.mobile {
        width: 100%;
        gap: 5rem;
        margin-bottom: 2rem;
    }
}

.fund-color {
    width: 0.315rem;
    height: 0.315rem;
    border-radius: 50%;
    display: inline-flex;
    &-chart {
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        display: inline-flex;
    }
    &-trade {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        display: inline-flex;
    }
}

.fund-name {
    font-size: 0.8rem;
    font-weight: 400;
    display: inline-flex;
    margin-left: 0.5rem;
    line-height: 2rem;
    white-space: nowrap;
}
.legend-container {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0.5rem 0;
}
.legend {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}

.trade-container {
    width: 48%;
    background-color: var(--background-main);
    padding: 1.2rem 3rem;
    border-radius: layout.$radius-7;
    margin-bottom: 2.4rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @include device.mobile {
        width: 100%;
        flex-wrap: wrap;
        margin-bottom: 0.9rem;
        padding: 0.8rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.data-container {
    width: 100%;
}
.trade-header {
    &-container {
        display: flex;
        margin-bottom: 1.6rem;
        align-items: center;
    }
    color: var(--invest-divest-header);
    font-weight: 400;
    font-size: 1rem;
    line-height: 2rem;
    margin-left: 1rem;
    @include device.mobile {
        font-size: 1rem;
        margin-left: 0.8rem;
    }
}

.bar-container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 1.5rem;
}
.bar {
    height: 0.8rem;
    display: inline-block;
    margin-right: 1rem;
    border-radius: 50px;
    &-invest {
        background-color: color.$primary-200; // same dark & light
    }
    &-divest {
        background-color: color.$primary-500; // same dark & light
    }
    &-value {
        font-weight: 400;
        font-size: 0.8rem;
        color: var(--invest-divest-value);
        display: inline-block;
        white-space: nowrap;
    }
}
.trade-box-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.6rem;
    justify-content: space-between;
    max-height: 44rem;
    overflow: auto;
}
.table-fund-cell {
    display: flex;
    align-items: center;
}
.chart-desktop-view {
    display: block;
    @include device.mobile {
        display: none;
    }
}
.chart-mobile-view {
    display: none;
    @include device.mobile {
        display: block;
    }
}
