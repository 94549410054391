@use 'styles/layout.scss' as layout;

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    max-width: 80rem;
    min-height: 100vh;
    margin: 0 auto;
    background: var(--background-box);
    padding: 5rem 3rem;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    position: relative;
    text-align: center;
}

.form-container {
    width: 100%;
}

.form-separator {
    display: flex;
    padding: 1.5rem;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    color: var(--text-general);

    &-line {
        height: 1px;
        width: 100%;
        background-color: var(--theme-text);
    }
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-top: 1rem;
    width: 100%;

    & > div {
        width: 50%;
        max-width: 20rem;
    }
}

.rights {
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    width: 100%;
}

.modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg);
    z-index: 6;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.modal-main {
    position: fixed;
    color: var(--general-body-text);
    background: var(--background-box);
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: layout.$radius-15;
    padding: 3rem 2.5rem;
    z-index: 7;
}

.checkbox-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    cursor: pointer;
}

.submit {
    width: 100%;
}

.intro-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.terms-policy-container {
    text-align: center;
    width: 100%;
}

.already-have-account {
    padding: 0.5rem 1rem;
    text-align: center;
    color: var(--text-general);

    & a {
        color: var(--text-general);
    }
}
