@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 1rem;
}

.table {
    color: var(--invest-divest-header);
    margin-bottom: 20px;
    border: none;
    border-collapse: collapse;
    box-sizing: border-box;
    text-align: left;
    white-space: nowrap;
    min-width: 100%;
    position: relative;
    border-collapse: separate;
    @include device.mobile {
        margin-bottom: 0;
    }
}

.thead {
    font-weight: 400;
    font-size: 1.4rem;
    & tr {
        & th:first-child {
            border-top-left-radius: layout.$radius-7;
            border-bottom-left-radius: layout.$radius-7;
            padding: 1rem 0 1rem 5rem;
        }
        & th:last-child {
            border-top-right-radius: layout.$radius-7;
            border-bottom-right-radius: layout.$radius-7;
            padding: 1rem 5rem 1rem 0;
        }
        @include device.mobile {
            & th:first-child {
                padding: 1.2rem 0 1.2rem 1.5rem;
            }
            & th:last-child {
                padding: 1.2rem 1.5rem 1.2rem 0;
            }
        }
    }
}
.main-thead {
    font-weight: 500;
    font-size: 1.2rem;
    @include device.mobile {
        font-size: 1rem;
    }
    & tr {
        & th:first-child {
            border-top-left-radius: layout.$radius-7;
            border-bottom-left-radius: layout.$radius-7;
            padding: 1rem 0 1rem 5rem;
        }
        & th:last-child {
            border-top-right-radius: layout.$radius-7;
            border-bottom-right-radius: layout.$radius-7;
            padding: 1rem 5rem 1rem 0;
        }
        @include device.mobile {
            border-radius: 0;
            & th:first-child {
                padding: 1.2rem 0 1.2rem 2.3rem;
                border-top-left-radius: layout.$radius-5;
                border-bottom-left-radius: layout.$radius-5;
            }
            & th:last-child {
                padding: 1.2rem 2.3rem 1.2rem 0;
                border-top-right-radius: layout.$radius-5;
                border-bottom-right-radius: layout.$radius-5;
            }
        }
    }
}

.th {
    cursor: pointer;
    box-sizing: border-box;
    text-align: left;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

.main-th {
    color: color.$white; // same dark & light
    background-color: color.$primary-500; // same dark & light
    padding: 1rem;
    @include device.mobile {
        color: color.$primary-400; // same dark & light
        background-color: var(--background-main);
        padding: 0.85rem;
    }
}
.tiny-th {
    color: var(--table-head-text);
    background-color: var(--background-main);
    font-weight: 400;
    font-size: 1.2rem;
    padding: 0.9rem;
}
.tr {
    & td:first-child {
        padding: 1rem 0 1rem 5rem;
    }
    & td:last-child {
        padding: 1rem 5rem 1rem 0;
    }
    &:not(:last-child) {
        & td {
            border-bottom: 1px solid var(--table-row-divider);
        }
    }
    &:hover {
        & td {
            border-bottom: none;
            &:nth-child(n-1) {
                border-bottom: none;
            }
        }
    }
    @include device.mobile {
        & td:first-child {
            padding: 0.9rem 0 0.9rem 2.3rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        & td:last-child {
            padding: 0.9rem 2.3rem 0.9rem 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
.tiny-tr {
    & td:first-child {
        padding: 0.9rem 0 0.9rem 5rem;
    }
    & td:last-child {
        padding: 0.9rem 5rem 0.9rem 0;
    }
    @include device.mobile {
        & td:first-child {
            padding: 0.9rem 0 0.9rem 1.5rem;
        }
        & td:last-child {
            padding: 0.9rem 1.5rem 0.9rem 0;
        }
    }
}

.tr-hover:hover {
    cursor: pointer;
    background-color: var(--table-row-hover);

    & td:first-child {
        border-top-left-radius: layout.$radius-5;
        border-bottom-left-radius: layout.$radius-5;
    }
    & td:last-child {
        border-top-right-radius: layout.$radius-5;
        border-bottom-right-radius: layout.$radius-5;
    }
}

.td {
    padding: 1rem;
    font-weight: 400;
    font-size: 1.2rem;
    @include device.mobile {
        font-size: 1.2rem;
    }
}
.tiny-td {
    padding: 1.2rem;
    font-weight: 400;
    color: var(--general-body-text);
}
