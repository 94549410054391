@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    max-width: 50rem;
    margin: 10rem auto;
    background: var(--background-box);
    border-radius: layout.$radius-15;
    padding: 5rem 3rem;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    position: relative;
    text-align: center;
    @include device.mobile {
        background: none;
        box-shadow: none;
        border-radius: 0;
        max-width: unset;
        margin: 2rem auto;
    }
}

.welcome {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 2rem;
    line-height: 2rem;
    @include device.mobile {
        font-size: 1.4rem;
    }
}

.verification {
    margin-bottom: 4rem;

    & p,
    & span {
        font-size: 1.6rem;
        line-height: 1.8rem;
        padding: 0.5rem;
        @include device.mobile {
            font-size: 1.4rem;
        }
    }

    & span {
        font-weight: bold;
        text-decoration: underline;
    }
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    @include device.mobile {
        flex-direction: column-reverse;
        align-items: space-between;
        justify-content: center;
    }
}

.resend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.timer {
    text-align: start;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    padding-left: 2rem;
}

.submit {
    color: #ffffff;
    border-radius: 7px;
    font-size: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    line-height: 4rem;
    padding: 0 2rem;
    cursor: pointer;
    border: none;
    background-color: var(--button-primary);
    box-shadow: 0px 4.23529px 14.1176px rgb(86 46 255 / 25%);
    min-width: 28rem;
    width: fit-content;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

button[disabled],
.disabled {
    cursor: not-allowed;
}

.btn-content {
    display: inline-flex;
    column-gap: 2rem;
    align-items: center;
}

.change {
    & a {
        color: var(--text-general);
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: bold;
    }
}

.alert {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: color.$green-800;
    font-weight: bold;

    &--err {
        color: color.$red-700;
    }
}
