@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    position: absolute;
    top: 124px;
    left: 206px;
    right: 0;
    display: flex;
    flex-direction: column;
    &-unprotected {
        left: 0;
    }
    @media (max-width: layout.$min-width) {
        padding-right: 20px;
        left: 226px;
        width: calc(layout.$min-width - 260px);
        &-unprotected {
            left: 20px;
            width: layout.$min-width;
        }
    }
    @include device.mobile {
        padding-right: 0;
        left: unset;
        right: unset;
        top: 71px;
        padding-bottom: 77px;
        width: calc(100% - 32px);
        margin: 0 1.4rem 1.6rem;
    }
}
