@use 'styles/devices.scss' as device;

.dropdown {
    position: relative;
    display: inline-block;
    color: var(--general-body-text);
}

.dropdown-content {
    border-radius: 10px;
    padding: 0.5rem 0;
    overflow: hidden;
    display: none;
    position: absolute;
    bottom: 0;
    background-color: var(--background-main);
    min-width: 8rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.in-header {
    position: fixed;
    top: 5rem;
    left: unset;
}

.visible {
    display: block;
}

.a {
    cursor: pointer;
    color: var(--general-body-text);
    text-decoration: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.3rem 0.2rem;

    &:hover {
        background-color: var(--background-box);
    }
}

.span {
    font-size: 1rem;
    font-weight: 500;
}

.button-container {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    padding-left: 0.8rem;
    @include device.mobile {
        font-size: 1rem;
    }
}
