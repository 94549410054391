@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    width: 40rem;
    align-self: flex-end;
    @include device.mobile {
        width: 100%;
    }
}
.capsule {
    padding: 0.4rem 2rem;
    margin-bottom: 0.8rem;
    border-radius: layout.$radius-5;
    background-color: var(--background-main);
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    line-height: 2rem;
}
.key {
    font-weight: 400;
    max-width: 90%;
    color: var(--general-body-text);
}

.value {
    font-weight: 500;
    color: var(--capsule-value);
}
