@use 'styles/colors' as color;

.loader {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    animation: spin 0.7s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}
