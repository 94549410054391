@use 'styles/layout.scss' as layout;

.wrapper {
    padding: 10rem;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    max-width: 50rem;
    margin: 0 auto;
    background: var(--background-box);
    border-radius: layout.$radius-15;
    padding: 5rem 3rem;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    position: relative;
    text-align: center;
}

.separator {
    background-color: var(--theme-text);
    width: 1px;
    min-height: 55rem;
}

.form-container {
    width: 100%;
}

.form-separator {
    display: flex;
    padding: 1.5rem;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    color: var(--text-general);

    &-line {
        height: 1px;
        width: 100%;
        background-color: var(--theme-text);
    }
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-top: 1rem;
    max-width: 50rem;

    & > div {
        width: 50%;
        max-width: 20rem;
    }
}

.rights {
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
}

.already-have-account {
    padding: 0.5rem 1rem;
    text-align: center;
    color: var(--text-general);

    & a {
        color: var(--text-general);
    }
}

.terms-policy-container {
    text-align: center;
    width: 100%;
}
