.container {
    display: inline-block;
    text-align: start;
}

.remaining {
    display: inline-block;
    width: 4rem;
    text-align: center;
}

.seconds {
    display: inline-block;
    width: 5rem;
    margin-left: -1.2rem;
}
