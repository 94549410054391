.container {
    position: fixed;
    width: 100%;
    z-index: 1;
    bottom: 0;
    left: 0;
    color: var(--text-general);
    background-color: var(--background-box);
    overflow: hidden;
    box-shadow: 5px -7px 60px rgba(0, 0, 0, 0.18);
    border-radius: 35px 35px 0px 0px;
    transition: 0.6s;
}

.separator {
    width: 100px;
    height: 0.4rem;
    margin: 0 auto;
    background: var(--bottom-sidebar-seperator);
    border-radius: 15px;
}
