@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    @include device.mobile {
        gap: 5rem;
        flex-direction: column;
    }
}

.select_fund {
    margin-bottom: 2rem;
    width: 32rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .label {
        color: var(--pl-header);
        font-size: 1.2rem;
        font-weight: 400;
        margin-bottom: 0.6rem;
        margin-left: 1.7rem;
    }
    @include device.mobile {
        width: 100%;
        margin: 0 auto 2rem;
    }
}

.box-first {
    width: 100%;
    display: flex;
    gap: 1.6rem;
    flex-direction: row;
    flex-wrap: nowrap;
    @include device.mobile {
        background: transparent !important;
        flex-direction: column;
        gap: 0;
    }
}

.info_box {
    background-color: var(--background-main);
    border-radius: layout.$radius-7;
    width: 100%;
    padding: 1rem 0 1rem 3rem;
    position: relative;
    overflow: hidden;
    &-title {
        font-weight: 400;
        font-size: 1.2rem;
        color: var(--info-box-title);
        line-height: 2.4rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: stretch;
    }
    &-value {
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--item-text-mobile);
        line-height: 2.7rem;
    }
    &-image {
        position: absolute;
        right: 1.8rem;
        top: 50%;
        width: 4rem;
        transform: translateY(-50%);
    }
    @include device.mobile {
        background-color: var(--background-box);
        margin-bottom: 1rem;
        &-image {
            right: 2.3rem;
            width: 3.2rem;
        }
    }
}

.box-second {
    margin-top: 1.6rem;
    margin-bottom: 4.8rem;
    @include device.mobile {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.box-third {
    margin-top: 1.6rem;
    @include device.mobile {
        margin-top: 0;
    }
}

.chart-metrics-container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    gap: 1rem;
    @include device.mobile {
        flex-direction: column;
        &-fullscreen {
            max-height: 80vw;
            overflow: auto;
            padding-right: 1rem;
        }
    }
}
.chart-container {
    display: flex;
    width: 75%;
    height: 25rem;
    @include device.mobile {
        min-height: 25rem;
        width: 100%;
    }
}
.metrics-container {
    display: flex;
    width: 25%;
    flex-direction: column;
    padding-top: 4rem;
    @include device.mobile {
        width: 100%;
    }
}

.metric-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    background-color: var(--background-main);
    border-radius: layout.$radius-5;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}
.metric-name {
    color: var(--metric-name);
    font-weight: 400;
    line-height: 2rem;
}
.metric-value {
    color: var(--metric-value);
    font-weight: 400;
    line-height: 2rem;
}
.pl-section-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    @include device.mobile {
        margin-top: 0;
    }
}
.pl-section {
    width: 24%;
    min-width: 7rem;
    @include device.mobile {
        width: 48%;
    }
}
.pl-header {
    margin-bottom: 1rem;
    color: var(--pl-header);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    @include device.mobile {
        font-size: 1rem;
        font-weight: 500;
    }
}
.pl {
    font-weight: 500;
    font-size: 1.2rem;
    color: var(--loss-value);
    margin-bottom: 0.5rem;
    &-profit {
        color: var(--profit-value);
    }
    @include device.mobile {
        font-size: 1rem;
    }
}

.pl-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-main);
    border-radius: layout.$radius-7;
    border: var(--input-border);
    padding: 0.4rem 1.6rem;
    @include device.mobile {
        justify-content: flex-end;
        gap: 1rem;
        flex-direction: row-reverse;
        padding: 0.4rem 0.8rem;
        background-color: var(--background-box);
    }
}

.pl-value-container {
    display: flex;
    align-items: center;
}
