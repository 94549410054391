@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.input-container {
    position: relative;
    width: 100%;
}

.date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-width: 14rem;
}

.fund-input {
    outline: none;
    border-radius: layout.$radius-5;
    width: 100%;
    height: 3rem;
    padding-left: 2.4rem;
    color: var(--period-select-text);
    background-color: var(--background-main);
    font-size: 1.2rem;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;

    @include device.mobile {
        padding-left: 1.6rem;
        font-size: 1rem;
    }
}

.items-container {
    display: none;
    visibility: hidden;
    position: absolute;
    top: 3rem;
    z-index: 7;
    width: 100%;
    background: var(--dropdown-bg);
    box-shadow: 0px 19.4528px 34.3285px 1.14428px rgba(90, 82, 128, 0.31);
    border-radius: 0px 0px layout.$radius-5 layout.$radius-5;
    overflow: hidden;
    @include device.desktop {
        &-visible {
            display: block;
            visibility: visible;
        }
    }
    &-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100% - 4.9rem);
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 0 1.8rem;
    }
}
.input_icon {
    position: absolute;
    right: 1.1rem;
    top: 0.5rem;
    width: 1.4rem;
    height: 1.4rem;
    @include device.mobile {
        display: none;
    }
}

.list {
    list-style: none;
    color: var(--box-header-divider);
    @include device.mobile {
        font-size: 1.2rem;
    }
}

.item {
    padding: 0.65rem 1.5rem;
    cursor: pointer;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 400;
    &:hover {
        background: var(--period-select-bg-gradient);
        border-left: 3px solid color.$green-600; // same dark & light
        padding-left: 0.9rem;
        font-weight: 500;
        color: var(--table-head-text);
        font-size: 1.2rem;
    }
    &:not(:last-child) {
        border-bottom: 1px solid var(--sidebar-divider);
    }
    @include device.mobile {
        padding: 0.9rem 1.5rem;
        &-selected {
            background: var(--period-select-bg-gradient);
            border-left: 3px solid color.$green-600; // same dark & light
            padding-left: 1.5rem;
            font-weight: 500;
            color: var(--table-head-text);
        }
        &:hover {
            padding-left: 1.2rem;
        }
    }
}

.modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg);
    z-index: 7;
    border-radius: layout.$radius-15;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.modal-main {
    position: fixed;
    background: var(--background-box);
    width: 396px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: layout.$radius-15;
    padding: 10px;
    z-index: 8;
    @include device.mobile {
        width: 90%;
    }
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.8rem;
    justify-content: space-between;
    margin: 1rem auto;
    width: 100%;
    & > button {
        width: 16rem;
        @include device.mobile {
            width: 100%;
        }
    }
}

.empty-checkbox {
    display: flex;
    justify-content: center;
    float: right;
    align-self: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.4rem;
    &-active {
        border: none;
    }
}
.range-picker-container-mobile {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 27rem;
    overflow-y: auto;
}

.mobile-back-icon {
    width: 2rem;
    &:hover {
        cursor: pointer;
    }
}
.back-button {
    display: flex;
    align-items: center;
    &-container {
        display: none;
        @include device.mobile {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.4rem;
            color: var(--back-button);
            margin-left: 2rem;
            margin-bottom: 1rem;
            &-text {
                margin-left: 0.9rem;
            }
        }
    }
}
