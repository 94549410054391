@use 'styles/colors.scss' as color;

$portfolio-1: #b57bff;
$portfolio-2: #fec37b;
$portfolio-3: #7b9efd;

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    & > img {
        width: 12%;
        cursor: pointer;
    }
}

.range-slider {
    position: relative;
    display: flex;
    width: 70%;
    &--disabled {
        width: 100%;
    }
    & > input {
        -webkit-appearance: none;
        width: 100%;
        height: 2px;
        background: grey;
        border-radius: 5px;
        background-color: var(--box-header-divider);
        background-repeat: no-repeat;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: color.$primary-500;
        }
        &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
        }
    }
    & > div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: color.$primary-500;
        height: 3px;
        border-radius: 3px;
    }
    &-portfolio-1 {
        & > input {
            &::-webkit-slider-thumb {
                background: $portfolio-1;
            }
        }
        & > div {
            background: $portfolio-1;
        }
    }
    &-portfolio-2 {
        & > input {
            &::-webkit-slider-thumb {
                background: $portfolio-2;
            }
        }
        & > div {
            background: $portfolio-2;
        }
    }
    &-portfolio-3 {
        & > input {
            &::-webkit-slider-thumb {
                background: $portfolio-3;
            }
        }
        & > div {
            background: $portfolio-3;
        }
    }
    &:hover {
        & > input:not(:disabled) {
            transition: height 0.2s;
            height: 4px;
            &::-webkit-slider-thumb {
                cursor: pointer;
                height: 16px;
                width: 16px;
                transition: height 0.2s;
            }
        }
        & > div:not(:aria-disabled) {
            height: 5px;
            transition: height 0.2s;
        }
    }
}
