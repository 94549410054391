@use 'styles/devices.scss' as device;
@use 'styles/layout.scss' as layout;

.main {
    width: 100%;
    min-width: 996px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: center;

    @media (max-width: 996px) {
        min-width: unset;
    }
}

.container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 996px) {
        width: 100%;
    }
}

.box {
    width: 70%;
    min-width: 40rem;
    background: var(--background-box);
    border-radius: layout.$radius-15;
    padding: 5rem 3rem;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    position: relative;

    &--small {
        width: 100%;
        padding-top: 3rem;
    }

    @include device.mobile {
        background: transparent;
        box-shadow: none;
        min-width: 100vw;
        padding: 0 5rem;
    }
}

.logo {
    text-align: center;
    margin-bottom: 6rem;

    & img {
        width: 70%;
    }

    &--small {
        margin-bottom: 3rem;

        & img {
            width: 30%;
        }
    }
}

.terms-privacy {
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
    gap: 1.3rem;
    font-size: 1rem;
    font-weight: 500;
}

.terms {
    color: var(--terms-conditions);
    text-decoration: none;
}

.privacy {
    color: var(--invest-divest-value);
    text-decoration: none;
}

.rights {
    text-align: center;
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;

    &--small {
        @include device.mobile {
            position: absolute;
        }
    }

    @include device.mobile {
        position: fixed;
        bottom: 1rem;
        left: 0;
        text-align: center;
        width: 100%;

        &--small {
            position: absolute;
        }
    }

    @include device.desktop {
        margin-top: 3rem;
    }
}

.theme-lang-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0 2rem;
    width: 70%;
    &--small {
        width: 100%;
    }
    & > div {
        width: 50%;
        max-width: 20rem;
    }
    @include device.mobile {
        width: 100%;
    }
}
