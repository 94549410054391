@use 'styles/layout.scss' as layout;
@use 'styles/colors.scss' as color;
@use 'styles/devices.scss' as device;

.container {
    width: 100vw;
    height: 77px;
    position: fixed;
    bottom: 0;
    background-color: var(--background-box);

    @include device.desktop {
        display: none;
    }
}
.list {
    list-style: none;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 0;
}
.list .item {
    cursor: pointer;
}
.item-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.active {
    color: var(--sidebar-active);
    font-weight: 500;
}
.item-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 12px;
}

.link {
    text-decoration: none;
    color: color.$gray-600; // same dark & light
}

.link .active .item-content {
    border-bottom: none;
}
