@use 'styles/layout.scss' as layout;
@use 'styles/colors.scss' as color;

.account-switcher {
    display: block;
    margin-top: 10vh;
}

.account-switcher-content {
    font-size: 14px;
    font-weight: 300;
    padding-top: 13px;
    margin-top: 6rem;
    width: 100%;
    background: var(--background-box);
    z-index: 1;
    overflow: hidden;
}

.section {
    padding: 1.6rem 2.5rem;
    border-bottom: 1px solid var(--sidebar-divider);
    &:hover {
        border-left: 4px solid var(--sidebar-active);
        background: var(--active-bg-gradient);
        color: var(--sidebar-active);
    }
}

.account-section {
    border-bottom: 1px solid var(--sidebar-divider);

    &-title {
        padding: 1.6rem 2.5rem;
        &:hover {
            color: var(--sidebar-active);
            padding: 1.6rem 2.5rem;
            border-left: 4px solid var(--sidebar-active);
            background: var(--active-bg-gradient);
        }
    }
    &-arrow {
        position: absolute;
        right: 22px;
    }
    &-content-block {
        color: var(--box-header-divider);
        padding: 5px;
        max-height: 40vh;
        overflow-y: auto;
        transition: all 0.3s;
    }
    &-content-none {
        max-height: 0;
        overflow-y: hidden;
        transition: all 0.3s;
    }
}

.username {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.account {
    cursor: pointer;
    padding: 12px 40px;
    position: relative;
}

.general {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    & > img {
        width: 2rem;
    }
}

.user-section {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.user-info {
    text-align: center;
    margin: 0 1rem;
}

.user-name {
    font-size: 16px;
    font-weight: 300;
    color: var(--capsule-value);
    line-height: 27.2px;
    white-space: nowrap;
}

.current-account {
    font-size: 12px;
    font-weight: 275;
    color: var(--period-select-text);
}

.user-icon {
    margin: 0 1rem;
    width: 3.7rem;
}

.log-out {
    border-bottom: none;
    color: var(--log-out);
}

.radio {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 3.33px solid var(--box-header-divider);
}

.radio-label {
    margin-left: 24px;
}

.theme-toggle-position {
    position: absolute;
    bottom: 3.6rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        width: 45%;
    }
}
