@use 'styles/colors.scss' as color;
@use 'styles/devices.scss' as device;

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 5rem;
}

.title {
    text-align: start;
    margin-bottom: 4rem;
    margin-left: 1.5rem;

    @include device.mobile {
        text-align: center;
        margin: 0;
    }
}

.title-heading {
    font-size: 3rem;
    font-weight: bold;
}

.form {
    @include device.mobile {
        margin-top: 4rem;
    }
}

.form-error-message {
    display: block;
    text-align: start;
    margin-left: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: color.$gray-600; // needs design
    margin-top: 1rem;
}

.server-error {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    color: color.$state-error; // needs design
}

.submit {
    width: 100%;
}

button[disabled],
.disabled {
    cursor: not-allowed;
}

.btn-content {
    display: inline-flex;
    column-gap: 2rem;
    align-items: center;
}

.username,
.password {
    margin-bottom: 1.3rem;
}

.footer {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
    column-gap: 2rem;

    @include device.mobile {
        position: unset;
        justify-content: center;
        margin-top: 4rem;
    }
}

.input-label {
    margin-bottom: 5px;
    line-height: 17.45px;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--general-body-text);
}

.google-container {
    display: flex;
    padding: 1.5rem;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

.google-separator {
    height: 1px;
    width: 100%;
    background-color: var(--theme-text);
}

.options {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;

    & a {
        color: var(--text-general);
    }
}
