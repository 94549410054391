@use 'styles/layout.scss' as layout;

.account-switcher {
    position: relative;
    display: inline-block;
    background-color: var(--background-main);
    padding: 0.8rem 1.6rem;
    border-radius: layout.$radius-7;
    min-width: 18rem;
}

.account-switcher-content {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    font-size: 1rem;
    font-weight: 500;
    top: 100%;
    right: 0;
    padding-top: 13px;
    width: 100%;
    min-width: 18rem;
    background: var(--dropdown-bg);
    color: var(--box-header-divider);
    border-bottom-left-radius: layout.$radius-5;
    border-bottom-right-radius: layout.$radius-5;
    box-shadow: 0px 20px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
    overflow: hidden;
}

.visible {
    visibility: visible;
    opacity: 1;
}

.section {
    padding: 0.8rem 1.6rem;
    margin-right: 0.9rem;
    &:not(:last-child) {
        border-bottom: 1px solid var(--sidebar-divider);
    }
    &:not(:last-child):hover {
        padding: 0.8rem 2.1rem;
        border-left: 4px solid var(--sidebar-active);
        background: var(--active-bg-gradient);
        color: var(--sidebar-active);
    }
}
.account-section {
    border-bottom: 1px solid var(--sidebar-divider);
    margin-right: 0.9rem;
    &-title {
        padding: 0.8rem 1.6rem;
        &:hover {
            padding: 0.8rem 2.1rem;
            border-left: 4px solid var(--sidebar-active);
            background: var(--active-bg-gradient);
            color: var(--sidebar-active);
        }
    }
    &-arrow {
        position: absolute;
        right: 22px;
    }
    &-content-block {
        padding: 5px;
        max-height: 50vh;
        overflow-y: auto;
        transition: all 0.3s;
    }
    &-content-none {
        max-height: 0;
        overflow-y: hidden;
        transition: all 0.3s;
    }
}

.username {
    font-size: 1.1rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.account {
    cursor: pointer;
    padding: 0.8rem 3.8rem;
    position: relative;
    color: var(--box-header-divider);
}

.general {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    & > img {
        width: 1.4rem;
    }
}

.user-section {
    display: flex;
    justify-content: flex-end;
    gap: 1.6rem;
    align-items: center;
    cursor: pointer;
    & > img {
        width: 1.4rem;
    }
}

.user-info {
    direction: rtl;
}

.user-name {
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--capsule-value);
    line-height: 1.8rem;
}
.current-account {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--period-select-text);
}

.user-icon {
    margin: 0 1rem;
    width: 3.7rem;
}

.log-out {
    color: var(--log-out);
}

.radio {
    position: absolute;
    height: 1.2rem;
    width: 1.2rem;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 3.33px solid var(--unchecked-radio-button);
}
.account:hover {
    color: var(--general-body-text);
    & .radio {
        border: 3.33px solid var(--hover-radio-button);
    }
}
.radio-label {
    margin-left: 2.2rem;
}
