@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;

.pagination-container {
    display: flex;
    list-style-type: none;
    align-items: center;
    border-radius: 50px;
    background-color: var(--background-main);
    padding: 0.6rem;
    font-size: 1.2rem;
    line-height: 2.7rem;
    font-weight: 400;
    margin: 2rem auto 0;
    .pagination-item {
        height: 2.4rem;
        text-align: center;
        margin: auto 0.9rem;
        color: var(--invest-divest-value);
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: center;
        letter-spacing: 0.01071em;
        border-radius: layout.$radius-15;
        width: 2.4rem;

        &.dots {
            font-size: 2.6rem;
            padding-bottom: 1.3rem;
        }
        &.dots:hover {
            border: none;
            cursor: default;
        }
        &:hover {
            border: 1px solid color.$primary-500; // same dark & light
            cursor: pointer;
        }

        &.selected {
            background-color: color.$primary-500; // same dark & light
            color: color.$white; // same dark & light
            font-weight: 500;
        }
    }
}
