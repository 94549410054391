.bouncing-loader {
    display: flex;
    justify-content: center;

    & > div {
        width: 16px;
        height: 16px;
        margin: 3px 6px;
        border-radius: 50%;
        background-color: #a3a1a1;
        opacity: 1;
        animation: bouncing-loader 0.6s infinite alternate;
    }

    & > div:nth-child(2) {
        animation-delay: 0.2s;
    }

    & > div:nth-child(3) {
        animation-delay: 0.4s;
    }
}

@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-16px);
    }
}
