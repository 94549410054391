@use 'styles/colors.scss' as color;

.form-error-message {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8.8px;
    text-align: start;
    font-size: 10px;
    font-weight: 400;
    color: color.$red-400; // needs design
    margin-top: 8px;
}
