@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;
.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2.4rem;
    @include device.mobile {
        flex-direction: column-reverse;
    }
}
.results {
    width: 75%;
    @include device.mobile {
        width: 100%;
    }
}

.charts-container {
    margin-top: 2rem;
}

.filters {
    width: 25%;
    position: sticky;
    top: 124px;
    max-height: 85vh;
    overflow-y: auto;
    & .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 1.6rem;
        color: var(--box-header);
        border-bottom: 1px solid var(--box-header-divider);
        padding-bottom: 1rem;
        margin: 1rem 0;
    }
    @include device.mobile {
        width: 100%;
        position: unset;
        top: unset;
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
        & .header {
            font-size: 1.4rem;
        }
    }
}

.items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    padding-bottom: 1rem;
    cursor: pointer;
    & > img {
        width: 2rem;
    }
}

.selected-index {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    & > img {
        cursor: pointer;
    }
}
.indices-container {
    padding-top: 1rem;
    max-height: 13rem;
    overflow-y: auto;
}
.available-period-container {
    border-radius: layout.$radius-10;
    padding: 1rem;
    background-color: var(--table-row-hover);
    color: var(--general-body-text);
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.dates {
    margin-top: 1rem;
    &-item {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.chart-container {
    display: flex;
    height: 45rem;
    @include device.mobile {
        min-height: 25rem;
        width: 100%;
    }
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1.6rem;
    @include device.mobile {
        width: 100%;
    }
}

.apply {
    width: 100%;
    &:disabled {
        &:hover {
            background-color: color.$primary-200;
        }
        background-color: color.$primary-200;
    }
}

.header {
    margin: 1.6rem 0;
}
.box {
    margin-bottom: 2rem;
    position: relative;
}
.no-return {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    text-align: center;
    color: var(--general-body-text);
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg);
    z-index: 9;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.modal-main {
    position: fixed;
    color: var(--general-body-text);
    background: var(--background-box);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: layout.$radius-15;
    padding: 3rem;
    z-index: 10;
    max-width: 95vw;
    max-height: 80vh;
    & > .close {
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        top: 3rem;
        right: 3rem;
        cursor: pointer;
    }
    @include device.mobile {
        width: 95%;
    }
}
.configs {
    width: 90%;
    &-container {
        overflow: auto;
        max-height: 60vh;
    }
}
.config-name {
    width: 396px;
}

.card {
    position: relative;
    border-radius: layout.$radius-10;
    background-color: var(--table-row-hover);
    color: var(--general-body-text);
    font-size: 1.2rem;
    margin-bottom: 1rem;
    width: 100%;
    min-width: 80rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 3rem;

    &-col {
        width: 9rem;
        &-name {
            max-width: 75%;
            overflow: hidden;
        }
        &-value {
            width: 25%;
            white-space: nowrap;
        }
        &-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
        }
        &--med {
            width: 13rem;
        }
    }
    &-header {
        position: sticky;
        top: 0;
        z-index: 12;
        margin-top: 0;
        color: color.$white; // same dark & light
        background-color: color.$primary-500; // same dark & light
    }
    &-hoverable {
        &:hover {
            box-shadow: 0px 0px 4px 1px var(--setting-active-tab);
            cursor: pointer;
        }
        &:active,
        &:focus {
            box-shadow: 0px 0px 4px 1px var(--setting-active-tab);
            transform: scale(0.95);
        }
    }
}

.blurred {
    &::before {
        content: '';
        z-index: 1;
        position: absolute;
        top: 7rem;
        left: 0;
        height: calc(100% - 7rem);
        width: 100%;
        backdrop-filter: blur(6px);
    }
}

.message {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--period-select-text);
    display: flex;
    align-items: center;
    gap: 1rem;
    & > img {
        width: 2rem;
    }

    &-disabled {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.7rem;
        color: var(--box-header);
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        &--red {
            color: var(--log-out);
        }
        & > img {
            width: 1.4rem;
            margin-right: 0.6rem;
            margin-top: 0.2rem;
        }
    }
}

.header {
    margin: 1.6rem 0;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0;
    & > .col {
        width: 23%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--last {
            justify-content: flex-end;
        }
        & .portfo {
            display: none;
        }
        &--dropdown {
            & > div {
                width: 100%;
            }
        }
    }
    @include device.mobile {
        flex-direction: column;
        transition: all 0.3s;
        &--assets {
            max-height: 2rem;
            overflow-y: hidden;
        }
        &--open {
            max-height: 20rem;
        }
        &--head {
            display: none;
        }
        & > .col {
            width: 100%;
            margin-bottom: 1rem;
            &--last {
                justify-content: space-between;
            }
            & .portfo {
                display: flex;
                font-size: 1.4rem;
            }
        }
    }
}

.head-row {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--box-header);
}

.asset_class {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--box-header);
    max-width: 80%;
}

.delete {
    cursor: pointer;
}

.arrow {
    display: none;
    @include device.mobile {
        display: inline;
        cursor: pointer;
        width: 1.4rem;
        margin-right: 1rem;
        transition: all 0.3s;
        &--open {
            transform: rotate(180deg);
        }
    }
}

.sum {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--box-header);
    width: 25%;

    @include device.mobile {
        width: fit-content;
    }
    &--error {
        color: var(--log-out);
    }
}

.currency {
    display: table-row;
    color: var(--invest-divest-header);
}

.currency-name,
.currency-value {
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    display: table-cell;
    @include device.mobile {
        padding: 0.5rem 1rem;
    }
}

.need-login {
    text-align: center;
    color: var(--log-out);
    font-size: 1.2rem;
    font-weight: 400;
    height: 1.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    & > img {
        width: 1.4rem;
        margin-right: 0.6rem;
    }
}

.login {
    text-decoration: none;
}
