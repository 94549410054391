.login {
    width: 100%;
    border: 1px solid var(--theme-text);
    border-radius: 5px;
    padding: 1rem 1rem;
    background: var(--background-box);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    color: var(--general-body-text);
    cursor: pointer;
}

.login:hover {
    background-color: var(--table-row-hover);
}
