// Fixed
$blue: #15b0fe;
$success-background: rgba(#56ca00, 0.2);
$success-forground: rgba(#56ca00, 0.9);
$failure-background: rgba(#ff4c51, 0.2);
$failure-foreground: rgba(#ff4c51, 0.9);
$green: #56c900;
$orange: #ffb400;
$pink: pink;
$purple: #9155fd;
$red: #ff444f;
$primary-main-2: #562eff;
$primary-2: #5913c4;
$primary-select: #958db6;
$gray-1: #262626;
$gray-2: #595959;
$gray-3: #8c8c8c;
$gray-4: #bfbfbf;
$gray-5: #d9d9d9;
$gray-6: #f0f0f0;
$gray-7: #f4f6f9;
$state-error: #ff3b3b;
$state-warning: #ffcc00;
$state-success: #06c270;
$design-pink: #e5d1fe;
// Theme
$background: #f7f9fb;
$black-1: #28243d;
$black-2: #312d4c;
$white-1: #ffffff;
$white-2: #f4f5fa;

$primary-white: rgba(231, 227, 252, 0.87);
$primary-black: #262626;

// ---- design colors ----

$white: #ffffff;
$black: #000000;
$bg-light: #f7f9fb;
$bg-dark: #21202f;
$bg-2-dark: #111018;

// gray
$gray-50: #f8f9fa;
$gray-100: #f1f3f5;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// primary
$primary-50: #f2efff;
$primary-100: #e1d6ff;
$primary-200: #c1adff;
$primary-300: #a084ff;
$primary-400: #8666ff;
$primary-500: #5a33ff;
$primary-600: #4425db;
$primary-700: #3119b7;
$primary-800: #211093;
$primary-900: #16097a;

// yellow
$yellow-50: #fff3c2;
$yellow-100: #ffeb9c;
$yellow-200: #ffe477;
$yellow-300: #ffdd55;
$yellow-400: #ffd736;
$yellow-500: #ffd11a;
$yellow-600: #ffcc00;
$yellow-700: #e6b800;
$yellow-800: #cfa600;
$yellow-900: #ba9500;

// green
$green-50: #befee2;
$green-100: #67faba;
$green-200: #42f9a9;
$green-300: #21f89a;
$green-400: #08f38c;
$green-500: #07d97d;
$green-600: #06c270;
$green-700: #05ab63;
$green-800: #049657;
$green-900: #04844d;

// red
$red-50: #ffefef;
$red-100: #ffbaba;
$red-200: #ff8b8b;
$red-300: #ff6161;
$red-400: #ff3b3b;
$red-500: #ff1515;
$red-600: #f30000;
$red-700: #d60000;
$red-800: #bc0000;
$red-900: #a50000;

// blue
$blue-50: #b5d2ff;
$blue-100: #86b6ff;
$blue-200: #5c9dff;
$blue-300: #3787ff;
$blue-400: #1673ff;
$blue-500: #0063f7;
$blue-600: #0057d9;
$blue-700: #004dbf;
$blue-800: #0044a8;
$blue-900: #003c94;
