.terms-privacy {
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
    gap: 1.3rem;
    font-size: 1rem;
    font-weight: 500;
}

.terms {
    color: var(--terms-conditions);
    text-decoration: none;
}

.privacy {
    color: var(--invest-divest-value);
    text-decoration: none;
}
