@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    display: flex;
    justify-content: space-between;
    max-height: calc(100vh - 144px);
    @include device.mobile {
        border-radius: layout.$radius-10;
        background-color: var(--background-box);
        flex-direction: column-reverse;
        max-height: unset;
        padding: 7px;
    }
    &-desktop {
        display: none;
        @include device.desktop {
            display: block;
        }
    }
    &-mobile {
        display: none;
        @include device.mobile {
            display: block;
        }
    }
}

.currency {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.currency-img {
    width: 2.1rem;
    height: 2.1rem;
    margin-right: 1rem;
    @include device.mobile {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
    }
}

.currency-value {
    padding: 0.1rem;
}
.currency-name {
    padding: 0.1rem;
}

.no-asset {
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    color: color.$gray-3; // same dark & light
    width: 100%;
    @include device.mobile {
        margin: 0.9rem auto;
    }
}
.table-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    @include device.mobile {
        width: 100%;
    }
}
.chart-container {
    width: 39%;
    display: flex;
    min-height: 40rem;
    margin-top: 2rem;
    @include device.mobile {
        width: 100%;
        min-height: unset;
    }
}
.chart-desktop-view {
    display: block;
    @include device.mobile {
        display: none;
    }
}
.chart-mobile-view {
    display: none;
    @include device.mobile {
        display: block;
    }
}

.mobile-capsule {
    background-color: var(--background-main);
    width: 100%;
    border-radius: layout.$radius-5;
    padding: 1.2rem 4rem 1.2rem 1.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2rem;
    &-key {
        font-size: 1.2rem;
        font-weight: 400;
        color: var(--general-body-text);
    }
    &-value {
        font-size: 1.4rem;
        font-weight: 500;
        color: var(--capsule-value);
    }
}

.tooltip {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    @media (max-width: layout.$min-width) {
        right: 3.6rem;
    }
    @include device.mobile {
        top: 0.7rem;
        right: 0.7rem;
    }
}
