@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;
.container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2.4rem;
    @include device.mobile {
        flex-direction: column-reverse;
    }
}
.results {
    width: 75%;
    @include device.mobile {
        width: 100%;
    }
}

.filters {
    width: 25%;
    position: sticky;
    top: 124px;
    max-height: calc(100vh - 148px);
    overflow-y: auto;
    & .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 1.6rem;
        color: var(--box-header);
        border-bottom: 1px solid var(--box-header-divider);
        padding-bottom: 1rem;
        margin: 1rem 0;
    }
    @include device.mobile {
        width: 100%;
        position: unset;
        top: unset;
        // display: flex;
        // flex-direction: column-reverse;
        gap: 2rem;
        max-height: unset;
        & .header {
            font-size: 1.4rem;
        }
    }
}

.items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.2rem;
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    padding-bottom: 1rem;
    cursor: pointer;
    & > img {
        width: 2rem;
    }
}

.selected-index {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem;
    color: var(--general-body-text);
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    & > img {
        cursor: pointer;
    }
}
.indices-container {
    padding-top: 1rem;
    max-height: 13rem;
    overflow-y: auto;
}
.available-period-container {
    border-radius: layout.$radius-10;
    padding: 1rem;
    background-color: var(--table-row-hover);
    color: var(--general-body-text);
    font-size: 1.2rem;
    margin-bottom: 1rem;
}
.dates {
    margin-top: 1rem;
    &-item {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.chart-container {
    display: flex;
    height: 45rem;
    @include device.mobile {
        min-height: 25rem;
        width: 100%;
    }
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1.6rem;
    @include device.mobile {
        width: 100%;
    }
}

.apply {
    width: 100%;
    &:disabled {
        &:hover {
            background-color: color.$primary-200;
        }
        background-color: color.$primary-200;
    }
}

.header {
    margin: 1.6rem 0;
}
.box {
    margin-bottom: 2rem;
    position: relative;
}
.no-return {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7rem;
    text-align: center;
    color: var(--general-body-text);
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg);
    z-index: 9;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.modal-main {
    position: fixed;
    color: var(--general-body-text);
    background: var(--background-box);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: layout.$radius-15;
    padding: 3rem;
    z-index: 10;
    & > .close {
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        top: 3rem;
        right: 3rem;
        cursor: pointer;
    }
    @include device.mobile {
        width: 95%;
    }
}
.configs {
    width: 50%;
    min-width: 50rem;
    max-width: 72rem;
    &-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;
    }
    @include device.mobile {
        width: 90%;
        min-width: unset;
        max-width: unset;
        &-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            flex-wrap: nowrap;
            max-height: 60vh;
            overflow-y: auto;
        }
    }
}
.config-name {
    width: 396px;
    @include device.mobile {
        width: 90%;
    }
}

.card {
    position: relative;
    border-radius: layout.$radius-10;
    background-color: var(--table-row-hover);
    color: var(--general-body-text);
    font-size: 1.2rem;
    margin-bottom: 1rem;
    width: 25%;
    min-width: 21rem;
    & > img {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 1rem;
        &:hover {
            transform: scale(1.5);
            cursor: pointer;
        }
    }
    @include device.mobile {
        width: 100%;
    }
}
.config-card {
    border-radius: layout.$radius-10;
    padding: 1rem;

    cursor: pointer;
    &-name {
        font-weight: 500;
        max-width: 70%;
    }
    &-line {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
    }
    &:hover {
        box-shadow: 0px 0px 4px 1px var(--setting-active-tab);
    }
    &:active,
    &:focus {
        box-shadow: 0px 0px 4px 1px var(--setting-active-tab);
        transform: scale(0.95);
    }
}

.blurred {
    &::before {
        content: '';
        z-index: 1;
        position: absolute;
        top: 7rem;
        left: 0;
        height: calc(100% - 7rem);
        width: 100%;
        backdrop-filter: blur(6px);
    }
}

.message {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    color: var(--period-select-text);
    display: flex;
    align-items: center;
    gap: 1rem;
    & > img {
        width: 2rem;
    }

    &-disabled {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.7rem;
        color: var(--log-out);
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        // text-align: center;
        & > img {
            width: 1.4rem;
            margin-right: 0.6rem;
            margin-top: 0.2rem;
        }
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
