@use 'styles/layout.scss' as layout;
@use 'styles/colors.scss' as color;
@use 'styles/devices.scss' as device;

.sidebar-container {
    width: 186px;
    position: fixed;
    bottom: 20px;
    top: 124px;
    background-color: var(--background-box);
    border-radius: layout.$radius-10;
    padding-top: 35px;
    @media (max-width: layout.$min-width) {
        position: sticky;
        position: -webkit-sticky; /* Safari */
        height: calc(100vh - 148px);
        margin-left: 20px;
    }
    @include device.mobile {
        display: none;
    }
}

.list {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
}
.list .item {
    cursor: pointer;
}
.item-content {
    padding: 11px 0;
    margin: 0 16px;
    border-bottom: var(--sidebar-divider) 1px solid;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.active {
    border-left: 4px solid var(--sidebar-active);
    background-image: var(--active-bg-gradient);
    color: var(--sidebar-active);
    font-weight: 500;
    & > .item-content {
        margin-left: 12px;
    }
}
.link:last-child .item .item-content {
    border-bottom: none;
}
.item-icon {
    width: 18px;
    margin-right: 8px;
}

.link {
    text-decoration: none;
    color: color.$gray-600; // same dark & light
}

.link .active .item-content {
    border-bottom: none;
}

.theme-toggle-position {
    position: absolute;
    bottom: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 1.6rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
}

.top-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.external_link {
    display: flex;
    gap: 0.5rem;
    padding-right: 0.8rem;
}

.link-img {
    width: 1.6rem;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}

.modal {
    max-width: 80rem;
    min-width: 50rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: var(--general-body-text);
    max-height: 75vh;
    overflow-y: auto;
    & ul {
        margin-top: 0.6rem;
        padding-left: 1.6rem;
        list-style-position: outside;
        list-style: disc;
    }
}

.title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.question {
    font-weight: 600;
    margin: 2rem 0 1rem;
}

.close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1.6rem;
    cursor: pointer;
}
