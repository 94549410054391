@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.input-container {
    position: relative;
    width: 100%;
}
.input {
    outline: none;
    border-radius: layout.$radius-5;
    border: var(--input-border);
    width: 100%;
    height: 4.8rem;
    padding-left: 2rem;
    background-color: var(--background-box);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    cursor: pointer;
    & .placeholder {
        position: absolute;
        top: 1.8rem;
        color: color.$gray-400; // same dark & light
    }
    & .value {
        position: absolute;
        top: 1.8rem;
        color: var(--info-box-value);
        line-height: 1.8rem;
        text-overflow: ellipsis;
        max-width: 30rem;
        white-space: nowrap;
        overflow: hidden;
    }
}

.date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 20%;
    @include device.mobile {
        width: 100%;
    }
}

.calendar {
    display: none;
    visibility: hidden;
    position: absolute;
    top: 6.2rem;
    z-index: 7;
    border-radius: layout.$radius-5;
    background: var(--background-main);
    @include device.desktop {
        &-visible {
            visibility: visible;
            display: block;
        }
    }
}
.input_icon {
    position: absolute;
    right: 2.3rem;
    top: 1.8rem;
    width: 1.6rem;
    height: 1.6rem;
}
.submit {
    margin: 1.6rem auto;
    width: calc(100vw - 5rem);
}
.calendar-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 4.9rem);
    justify-content: space-between;
    flex-wrap: nowrap;
}
