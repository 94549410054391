@use 'styles/devices.scss' as device;

.container {
    width: 36px;
    height: 36px;
    transform: rotate(-90deg);
    @include device.mobile {
        width: 26px;
        height: 26px;
    }
}

.background {
    stroke: var(--background-main);
    stroke-width: 4px;
}

.rise-background {
    fill: rgba(46, 139, 87, 0.09);
}

.fall-background {
    fill: rgba(248, 69, 99, 0.09);
}

.progress {
    fill: none;
    stroke-linecap: round;
    stroke-dasharray: 100 100;
    stroke-width: 4px;
}

.rise-progress {
    stroke: var(--profit-value);
}

.fall-progress {
    stroke: var(--loss-value);
}
