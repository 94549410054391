@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;

.container {
    width: 14rem;
    background-color: var(--background-main);
    border-radius: layout.$radius-5;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 10px;
}
.checkbox {
    display: none;
}

.label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 4px;
    position: relative;
}

.ball {
    width: 50%;
    height: 100%;
    background-color: var(--button-primary);
    border-radius: layout.$radius-5;
    transition: transform 0.2s linear;
    z-index: 0;
}

.checkbox:checked + .label .ball {
    transform: translateX(100%);
}

.toggle {
    color: color.$white;
    font-weight: 500;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
}

.toggle-word {
    color: var(--general-body-text);
    font-weight: 500;
}

.left {
    left: 25%;
}
.right {
    left: 75%;
}
