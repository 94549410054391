@use './colors.scss' as color;
@use './fonts.scss' as font;
@use './layout.scss' as layout;
@use './devices.scss' as device;

*,
html,
body {
    font-family: font.$font_stack;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    box-sizing: border-box;
    /* scrollbar styles for mozilla firefox */
    scrollbar-color: #888 #f1f1f1;
    scrollbar-width: thin;
}

html,
body {
    background-color: var(--background-main);
}

::placeholder {
    color: var(--text-general);
    opacity: 1;
}

:-ms-input-placeholder {
    color: var(--text-general);
}

::-ms-input-placeholder {
    color: var(--text-general);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

// global scrollbar styles
@include device.desktop {
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: layout.$radius-5;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: layout.$radius-5;
    }
}
// end of scrollbar styles

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--background-main) inset !important;
    box-shadow: 0 0 0 30px var(--background-main) inset !important;
    background-color: var(--background-main) !important ;
    -webkit-text-fill-color: var(--period-select-text) !important;
}
.echarts-for-react > div:first-child {
    width: 100% !important; // Workaround for Echarts
}
/* Custom styles for react-date-picker */
// start react-date-picker
.react-date-picker__wrapper {
    border: none;
}

.react-date-picker__calendar-button__icon.react-date-picker__button__icon,
.react-date-picker__clear-button__icon.react-date-picker__button__icon {
    stroke: var(--text-general);
}

// start calendar
.react-calendar {
    width: 100%;
    border: none;
    border-radius: layout.$radius-5;
    overflow: hidden;
    background-color: transparent;
    font-size: 0.9rem;
    color: var(--general-body-text);
    font-weight: 300;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
    font-weight: 600;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: inherit;
}
.react-calendar__navigation button[disabled] {
    background-color: inherit;
    color: inherit;
}
abbr[title] {
    text-decoration: none;
}
.react-calendar__tile {
    font-size: 0.9rem;
    padding: 1rem;
}
.react-calendar button {
    color: var(--text-general);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    border: 1px solid color.$primary-500;
    padding: 0.9rem;
    border-radius: 50px;
    background-color: inherit;
}
.react-calendar__tile--now {
    border-radius: 50px;
    font-weight: bold;
    border: 1px solid color.$gray-4;
    background-color: inherit;
    color: inherit;
    font-weight: 900;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    border: 1px solid color.$primary-500;
    background-color: inherit;
    border-radius: 50px;
    color: color.$primary-500;
}
.react-calendar__tile--active {
    background: color.$primary-500;
    border-radius: 50px;
    color: color.$white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: color.$primary-500;
    color: color.$white;
}
.react-calendar__tile:disabled {
    background-color: inherit;
    color: color.$gray-4;
}
.range-calendar {
    & .react-calendar__tile--hover {
        background-color: rgba(86, 46, 255, 0.1);
    }
    & .react-calendar__tile:enabled:hover {
        border: none;
    }
    & .react-calendar__tile--active:enabled {
        overflow: visible !important;
    }
    & .react-calendar__tile--active:enabled:hover {
        background-color: rgba(86, 46, 255, 0.1);
        border-radius: 0;
        overflow: visible !important;
        position: relative;
        &::before {
            content: ' ';
            border-radius: 50px;
            border: 1px solid color.$primary-500;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
        }
    }
    & .react-calendar__tile:enabled:focus {
        border: none;
    }
    & .react-calendar__tile--active {
        background: color.$primary-500;
        border-radius: 50px;
        color: var(--sidebar-active) !important;
    }
    & .react-calendar__tile--range {
        background: rgba(86, 46, 255, 0.1);
        border-radius: 0;
    }
    & .react-calendar__tile--rangeEnd {
        border-radius: 50px;
        background: color.$primary-500;
        color: color.$white !important;
        position: relative;
        overflow: visible !important;
        &::before {
            content: ' ';
            background-color: rgba(86, 46, 255, 0.1);
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0;
            left: 0;
            display: block;
        }
        &:hover {
            background-color: color.$primary-500 !important;
            border-radius: 50px !important;
        }
    }
    & .react-calendar__tile--rangeStart {
        border-radius: 50px;
        background: color.$primary-500;
        color: color.$white !important;
        position: relative;
        overflow: visible !important;
        &::before {
            content: ' ';
            background-color: rgba(86, 46, 255, 0.1);
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0;
            left: 50%;
            display: block;
        }
        &:hover {
            background-color: color.$primary-500 !important;
            border-radius: 50px !important;
        }
    }
}

.full-screen {
    &-icon {
        display: none;
    }
    @include device.mobile {
        position: fixed;
        top: 0;
        left: 0;
        transform: rotate(90deg) translateY(-100%);
        transform-origin: top left;
        height: 100vw !important;
        width: 100vh !important;
        z-index: 100;
        transition: all 0.3s;
        overflow: hidden;
        &-icon {
            margin-right: 1rem;
            display: inline;
        }
        &-close {
            margin-left: 1rem;
            margin-bottom: 0.5rem;
        }
    }
}

.desktop-view {
    display: block;
    @include device.mobile {
        display: none;
    }
}
.mobile-view {
    display: none;
    @include device.mobile {
        display: block;
        &-hidden {
            display: none;
        }
    }
}
.arrow {
    transform: rotate(0deg);
    transition: transform 0.3s;
    &-open {
        transform: rotate(-180deg);
        transition: transform 0.3s;
    }
}

.box {
    background: var(--background-box);
    border-radius: layout.$radius-10;
    padding: 1.6rem;
    width: 100%;
    transition: all 0.3s;
}

.box-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.8rem;
    color: var(--box-header);
    border-bottom: 1px dashed var(--box-header-divider);
    padding-bottom: 1.5rem;
    @include device.mobile {
        padding-bottom: 1rem;
        &--no-border {
            border: none;
            padding-bottom: 0.7rem;
            padding-left: 0.8rem;
        }
    }
    &_main {
        font-weight: 600;
        margin-right: 0.9rem;
        max-width: 80%;
        @include device.mobile {
            font-size: 1.2rem;
        }
    }
    &_info {
        font-weight: 300;
        display: inline;
        @include device.mobile {
            font-size: 1.2rem;
        }
    }
    &_icons {
        display: flex;
        align-items: center;
        gap: 2rem;
    }
}

.period-position {
    margin: 1.6rem auto 0;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    gap: 1rem;
}

.button {
    &-primary {
        color: color.$white;
        align-self: flex-end;
        border-radius: layout.$radius-7;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        cursor: pointer;
        border: none;
        background-color: var(--button-primary);
        box-shadow: 0px 4.23529px 14.1176px rgba(86, 46, 255, 0.25);
        &:hover {
            background-color: var(--button-primary-hover);
        }
    }
    &-outline {
        color: var(--button-outline);
        align-self: flex-end;
        border-radius: layout.$radius-7;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        cursor: pointer;
        background: transparent;
        border: 1px solid var(--button-outline);
        &:hover {
            border: 1px solid var(--button-outline-hover);
            color: var(--button-outline-hover);
        }
        &-primary {
            border: 2px solid var(--button-primary);
            color: var(--button-primary);
            font-weight: 500;
            font-size: 1.6rem;
            background: transparent;
            border-radius: layout.$radius-7;
            cursor: pointer;
            &:hover {
                border: 2px solid var(--button-primary-hover);
                color: var(--button-primary-hover);
            }
        }
    }
    &-48 {
        height: 4.8rem;
    }
    &-40 {
        height: 4rem;
    }
}

.grecaptcha-badge {
    z-index: 10;
}

.page-header {
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: 500;
    color: var(--box-header);
    margin-bottom: 1rem;
    @include device.mobile {
        font-size: 1.4rem;
    }
}

.modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--modal-bg);
    z-index: 6;
    box-shadow: 0px 16px 50px 4px rgba(90, 82, 128, 0.1);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.modal-main {
    position: fixed;
    color: var(--general-body-text);
    background: var(--background-box);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: layout.$radius-15;
    padding: 31px 36px;
    z-index: 7;
    @include device.mobile {
        width: 95%;
    }
}
