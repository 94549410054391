@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;

.container {
    width: 100%;
    height: 35px;
    background-color: var(--background-main);
    border-radius: 50px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 10px;
}
.checkbox {
    opacity: 0;
    position: absolute;
}

.label {
    cursor: pointer;
    width: 100%;
    height: 27px;
    display: flex;
    align-items: center;
    padding: 5px;
    position: relative;
}

.ball {
    width: calc(50% - 5px);
    height: 27px;
    background-color: var(--button-primary);
    position: absolute;
    left: 5px;
    border-radius: 50px;
    transition: transform 0.2s linear;
    z-index: 1;
}

.checkbox:checked + .label .ball {
    transform: translateX(100%);
}

.theme {
    display: flex;
    align-items: center;
    z-index: 2;
    color: var(--theme-text);
    font-weight: 500;
}

.theme-word {
    color: color.$white; // same dark & light
    font-weight: 500;
}

.light {
    position: absolute;
    left: 25%;
    transform: translateX(-50%);
}
.dark {
    position: absolute;
    left: 75%;
    transform: translateX(-50%);
}
.icon {
    margin-right: 8px;
    height: 10px;
}
