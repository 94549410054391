@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: calc(100vh - 144px - 97px);
    @include device.mobile {
        max-height: unset;
    }
}

.currency {
    display: table-row;
    color: var(--invest-divest-header);
}

.currency-img {
    width: 1.8rem;
    height: 1.8rem;
    vertical-align: middle;
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
    @include device.mobile {
        margin: 0.2rem 0.5rem 0 0;
    }
}

.currency-image-table {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0.5rem 1.7rem 0 0;
    vertical-align: middle;
}

.currency-name,
.currency-value {
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    display: table-cell;
    @include device.mobile {
        padding: 0.5rem 1rem;
    }
}

.filters-container {
    color: var(--text-general);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    margin-bottom: 2.3rem;
    @include device.mobile {
        display: none;
        &-view {
            display: flex;
            flex-direction: column;
        }
    }
}

.fund-input-container {
    width: 43rem;
    @include device.mobile {
        width: 100%;
    }
}

.submit {
    min-width: 14rem;
    align-self: flex-end;
    @include device.mobile {
        width: 50%;
        margin-top: 2rem;
    }
}
.button-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    @include device.mobile {
        width: 100%;
    }
}
.calendar {
    visibility: hidden;
    position: absolute;
    top: 6.2rem;
    z-index: 7;
    &-visible {
        visibility: visible;
    }
}

.trade-container {
    width: 100%;
    height: 10rem;
    background-color: var(--background-main);
    padding: 1.2rem 1.6rem 0;
    border-radius: layout.$radius-7;
    margin-bottom: 3.5rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @include device.mobile {
        background-color: var(--background-box);
        margin-bottom: 1.6rem;
    }
}
.data-container {
    width: 70%;
    @media (max-width: 1250px) {
        width: 100%;
    }
}
.trade-header {
    color: var(--info-box-value);
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
}
.trade-type {
    display: flex;
}
.trade-image {
    width: 28rem;
    margin-top: 0.5rem;
    @media (max-width: 1250px) {
        display: none;
    }
}

.trade-type {
    font-weight: 400;
    font-size: 1rem;
    line-height: 2.1rem;
    color: var(--period-select-text);
    display: table-cell;
    padding-right: 1.9rem;
}

.bar-container {
    display: table-cell;
    width: 80%;
}
.bar {
    height: 0.8rem;
    display: inline-block;
    margin-right: 1rem;
    border-radius: 50px;
    &-invest {
        background-color: color.$primary-200; // same dark & light
    }
    &-divest {
        background-color: color.$primary-500; // same dark & light
    }
    &-value {
        font-weight: 400;
        font-size: 0.8rem;
        color: var(--invest-divest-value);
        display: inline-block;
        white-space: nowrap;
    }
}

.card-container {
    width: 100%;
    background-color: var(--background-box);
    padding: 1.6rem;
    border-radius: layout.$radius-7;
    margin-bottom: 0.8rem;
}
.card-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.card-action {
    font-size: 1.4rem;
    color: var(--general-body-text);
    margin-bottom: 1rem;
}
.card-fund {
    font-size: 1.2rem;
    color: var(--period-select-text);
}
.card-date {
    font-size: 1rem;
    color: var(--period-select-text);
}
.card-details {
    margin-top: 1.7rem;
    width: 100%;
    background-color: var(--background-main);
    padding: 0.9rem;
    border-radius: layout.$radius-5;
    font-size: 1.2rem;
    overflow: hidden;
    transition: max-height 1s cubic-bezier(1, 0, 1, 0.99);
    max-height: 4.4rem;
    &-closed {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }
    &-currency-row {
        visibility: visible;
        opacity: 1;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        transition: opacity 0.2s cubic-bezier(1, 0, 1, 0.99);
        color: var(--text-general);
    }
    &-open {
        max-height: 550rem;
    }
}

.share-count-part {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    & > img {
        width: 1.4rem;
    }
}
.number {
    color: var(--invest-divest-header);
    margin-right: 1rem;
    font-weight: 500;
}
.count {
    color: var(--box-header-divider);
    font-weight: 300;
    margin-right: 1.6rem;
}

.assets {
    margin-top: 1rem;
}
.hidden {
    visibility: hidden;
    opacity: 0;
}
.mobile-page-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
}
.mobile-page-title {
    font-weight: 500;
    color: var(--box-header);
}
.mobile-page-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.mobile-page-filter-icon {
    width: 2rem;
    &:hover {
        cursor: pointer;
    }
}
.mobile-page-filter-text {
    font-weight: 300;
    color: var(--period-select-text);
    align-self: center;
    padding-right: 1rem;
}
.back-button {
    display: flex;
    align-items: center;
    &-container {
        display: none;
        @include device.mobile {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.4rem;
            color: var(--back-button);
            &-text {
                margin-left: 0.5rem;
            }
        }
    }
}

.load-more {
    height: 4.8rem;
    display: block;
    margin: 1rem auto;
    width: 14rem;
    align-self: flex-end;
    border-radius: layout.$radius-7;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    border: 1px solid var(--button-outline);
    color: var(--button-outline);
    width: 100%;
    background-color: transparent;
    &:disabled {
        border: 1px solid var(--box-header-divider);
        color: var(--box-header-divider);
    }
}
