@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.input-container {
    position: relative;
    width: 100%;
}

.date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
}

.fund-input {
    outline: none;
    border-radius: layout.$radius-7;
    border: var(--input-border);
    width: 100%;
    height: 4.8rem;
    padding-left: 2rem;
    background-color: var(--background-box);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    cursor: pointer;
    & .placeholder {
        position: absolute;
        top: 1.8rem;
        color: color.$gray-400; // same dark & light
    }
    & .value {
        position: absolute;
        top: 1.8rem;
        color: var(--info-box-value);
        line-height: 1.8rem;
        text-overflow: ellipsis;
        max-width: 30rem;
        white-space: nowrap;
        overflow: hidden;
    }
}

.items-container {
    display: none;
    @include device.desktop {
        box-shadow: var(--dropdown-shadow);
        display: block;
        visibility: hidden;
        position: absolute;
        top: 5.6rem;
        z-index: 7;
        width: 100%;
        padding: 0.6rem 0;
        background: var(--dropdown-bg);
        border-radius: layout.$radius-7;
        &-visible {
            visibility: visible;
        }
    }
    &-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: calc(100% - 4.9rem);
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}
.input_icon {
    position: absolute;
    right: 2.3rem;
    top: 1.8rem;
    width: 1.6rem;
    height: 1.6rem;
}

.list {
    list-style: none;
    color: var(--item-text);
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 400;
    max-height: 37rem;
    overflow-y: auto;
    @include device.mobile {
        color: var(--item-text-mobile);
        font-weight: 300;
    }
}

.item {
    padding: 0.8rem 1.6rem;
    line-height: 1.5rem;
    cursor: pointer;
    @include device.mobile {
        padding: 1.4rem 2.5rem;
    }
    &:not(:last-child) {
        border-bottom: 1px solid var(--sidebar-divider);
    }
    &:hover {
        @include device.desktop {
            background-color: var(--item-hover-bg);
        }
    }
    &-selected {
        @include device.mobile {
            background: var(--active-bg-gradient);
            color: var(--capsule-value);
            font-weight: 500;
        }
    }
}
.submit {
    margin: 1.6rem 2.5rem;
    width: calc(100vw - 5rem);
}
