@use 'styles/devices.scss' as device;

.container {
    max-width: 90%;
    min-height: 30rem;
    margin: 7rem 0 0;
}

.logo {
    width: 70%;
    margin-bottom: 3rem;
    @include device.mobile {
        width: 50%;
        margin: 0 auto 3rem;
        display: block;
    }
}

.title {
    font-size: 1.4rem;
    color: var(--general-body-text);
    line-height: 1.8rem;
    text-align: start;
    @include device.mobile {
        font-size: 1.2rem;
    }
}

.list {
    list-style-type: disc;
    margin: 2rem 3rem 6rem 3rem;
    font-size: 1.4rem;
    color: var(--general-body-text);
    @include device.mobile {
        font-size: 1.2rem;
    }
}

.list li {
    padding: 0.6rem;
    text-align: start;
    line-height: 1.8rem;
    @include device.mobile {
        padding: 0.4rem;
    }
}
