@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.input-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.input {
    outline: none;
    border-radius: layout.$radius-5;
    border: var(--input-border);
    width: 45%;
    height: 3.4rem;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
    color: var(--general-body-text);
    background-color: var(--background-main);
    font-size: 1.2rem;
    font-weight: 300;
    & .placeholder {
        position: absolute;
        top: 1.1rem;
        color: var(--general-body-text);
    }
    & .value {
        position: absolute;
        top: 1.1rem;
        color: var(--general-body-text);
        line-height: 1.2rem;
        text-overflow: ellipsis;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
    }
}

.date {
    display: flex;
    flex-direction: column;
    position: relative;
}

.calendar {
    z-index: 7;
    width: 100%;
    @include device.desktop {
        &.dropdown {
            visibility: visible;
            display: block;
        }
    }
    @include device.mobile {
        &.dropdown {
            visibility: hidden;
            display: none;
        }
    }
}

.pointer {
    cursor: pointer;
}

.dropdown {
    background: var(--background-main);
    max-height: 500px;
    position: absolute;
    top: 4rem;
    overflow: hidden;
    border-radius: layout.$radius-7;
    &_close {
        max-height: 0;
        overflow: hidden;
    }
}

.submit {
    margin: 1.6rem auto;
    width: 100%;
}
