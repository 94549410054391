@use 'styles/colors.scss' as color;
@use 'styles/layout.scss' as layout;
@use 'styles/devices.scss' as device;

.container {
    position: relative;
    height: calc(100vh - 144px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include device.mobile {
        height: unset;
    }
}

.timer {
    &-container {
        margin-bottom: 1rem;
        color: var(--general-body-text);
        font-size: 1.8rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        & > img {
            width: 4rem;
            margin-right: 1rem;
        }
        & > span > span {
            color: color.$primary-500;
        }

        @include device.mobile {
            font-size: 1.4rem;
            line-height: 1.7rem;
            & > span > span {
                white-space: nowrap;
            }
        }
    }
}

.stats-box {
    width: calc(50% - 0.8rem);
    &-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        gap: 1.6rem;
        width: 100%;
    }

    @include device.mobile {
        width: 100%;
    }
}

.card {
    width: 48%;
    background-color: var(--background-main);
    padding: 1.2rem 2rem;
    border-radius: layout.$radius-7;
    @include device.mobile {
        width: 100%;
    }
    &-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 2rem;
        gap: 1.6rem;
    }
    &-header {
        &-container {
            display: flex;
            margin-bottom: 1.6rem;
            align-items: center;
        }
        color: var(--invest-divest-header);
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
    }
}

.report {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > img {
        width: 4rem;
    }
    & > div {
        font-size: 2rem;
        color: color.$primary-500;
        width: 70%;
    }
}

.header {
    line-height: 3rem;
    &-info {
        font-weight: 500;
        font-size: 1.4rem;
    }
}

.bar-container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: 1.5rem;
}
.bar {
    height: 0.8rem;
    display: inline-block;
    margin-right: 1rem;
    border-radius: 50px;
    &-invest {
        background-color: color.$primary-200; // same dark & light
    }
    &-divest {
        background-color: color.$primary-500; // same dark & light
    }
    &-value {
        font-weight: 400;
        font-size: 0.8rem;
        color: var(--invest-divest-value);
        display: inline-block;
        white-space: nowrap;
    }
}
.currency {
    display: table-row;
    color: var(--invest-divest-value);
    font-size: 1rem;
    &-img {
        width: 1.2rem;
        height: 1.2rem;
        vertical-align: middle;
        margin-bottom: 0.5rem;
        margin-top: 0.25rem;
        @include device.mobile {
            margin: 0.2rem 0.5rem 0 0;
        }
    }

    &-name,
    &-value {
        padding: 0 0.5rem;
        margin-bottom: 0.5rem;
        display: table-cell;
        @include device.mobile {
            padding: 0.5rem 1rem;
        }
    }
}
